<template>
  <div class="questionnaire">
    <div>
      <h2
        class="question-title"
        v-if="currentIndex >= '0' && currentIndex <= '4'"
      >
        请根据您的实际情况选择符合的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '4' && currentIndex <= '20'"
      >
        在过去的两周里，你生活中以下症状出现的频率有多少？
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '20' && currentIndex <= '28'"
      >
        下面描述了人们对人生的认知、感受和态度，请根据您当前的情况，选择最合适的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '28' && currentIndex <= '35'"
      >
        最近半年，您有过下列行为吗？请选择对应选项。
      </h2>

      <h2
        class="question-title"
        v-if="currentIndex > '35' && currentIndex <= '55'"
      >
        心理健康素养指运用心理健康知识、技能和态度,
        保持和促进本人及他人心理健康的能力，提升心理健康素养是促进心理健康的重要途径。心理健康素养是下面是关于心理健康相关认识的描述，请根据题目描述，结合自己的当前的认识，选择最符合您情况的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '57' && currentIndex <= '62'"
      >
        请根据您工作生活中的实际情况进行作答。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '62' && currentIndex <= '72'"
      >
        请指出最近半年以来，你的真实情况和以下陈述的符合程度。如果有些特殊情境并未发生，则假设如果真的发生了，你的感受会是怎样。请选择最能描述你的感觉的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '72' && currentIndex <= '77'"
      >
        根据题目描述，选择最符合你当前情况的描述。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '77' && currentIndex <= '80'"
      >
        根据题目描述，选择最符合您情况的选项。
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '80' && currentIndex <= '84'"
      >
        EAP服务需求调查
      </h2>
      <h2
        class="question-title"
        v-if="currentIndex > '84' && currentIndex <= '91'"
      >
        请在下列个人基本信息调查表中按照您的实际状况填写或选择。
      </h2>

      <div v-if="currentQuestion" class="question-section">
        <span class="progress-text"
          >{{ currentIndex + 1 }}/{{ questions.length }}</span
        >
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressWidth }"></div>
        </div>

        <div class="question">
          <h3 class="question-title">
            {{ currentQuestion.subject || currentQuestion.title }}
          </h3>
          <!-- <h3 class="question-title" v-if="currentIndex == '79'">
            已完成全部答题
          </h3> -->
          <!-- 单选 -->
          <div class="radio-label" v-if="currentIndex == '78'">
            <!-- 联系电话： -->
            <!-- <input
              type="text"
              v-model="phone"
              placeholder="请输入联系电话"
              style="position: relative; top: 0.23rem; left: 0.6rem"
            /> -->
          </div>
          <div v-if="currentQuestion.type === 'radio'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="radio"
                :id="'option-' + currentQuestion.id + '-' + index"
                :value="option"
                v-model="answers[currentQuestion.id]"
                @change="saveAnswer"
                class="radio-input"
              />
              <label
                :for="'option-' + currentQuestion.id + '-' + index"
                class="radio-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 多选 -->
          <div v-if="currentQuestion.type === 'checkbox'" class="options">
            <div
              v-for="(option, index) in parsedOptions"
              :key="index"
              class="option"
            >
              <input
                type="checkbox"
                :id="'checkbox-' + currentQuestion.id + '-' + index"
                :value="option"
                v-model="answers[currentQuestion.id]"
                @change="handleCheckboxChange"
                class="checkbox-input"
              />
              <label
                :for="'checkbox-' + currentQuestion.id + '-' + index"
                class="checkbox-label"
              >
                {{ option }}
              </label>
            </div>
          </div>

          <!-- 下拉框 -->
          <div
            v-if="currentQuestion.type === 'select'"
            class="select-container"
          >
            <select
              v-model="answers[currentQuestion.id]"
              @change="saveAnswer"
              class="custom-select"
            >
              <option
                v-for="(option, index) in parsedOptions"
                :key="index"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
          </div>
          <input
            type="text"
            class="qita"
            v-if="
              currentIndex == '4' ||
              currentIndex == '56' ||
              currentIndex == '59' ||
              currentIndex == '62' ||
              currentIndex == '82' ||
              currentIndex == '90'
            "
            v-model="inputvalue"
            placeholder="请填写其他内容"
          />
          <!-- <div v-if="currentIndex == '91'">
            手机号:
            <input type="text" class="" v-model="phone" />
          </div> -->
        </div>
        <div class="navigation">
          <button
            @click="prevQuestion"
            :disabled="currentIndex === 0"
            class="nav-button"
          >
            上一题
          </button>
          <button
            v-if="currentIndex < questions.length - 1"
            @click="nextQuestion"
            class="nav-button"
          >
            下一题
          </button>
          <button v-else @click="submitAnswers" class="submit-button">
            提交
          </button>
        </div>
      </div>
      <div v-else class="loading">
        <p>加载中...</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      questions: [], // 所有题目
      currentIndex: 0, // 当前题目索引
      answers: {}, // 用户选择的答案
      // phone: "",
      jianyi: "",
      inputvalue: "",
    };
  },
  computed: {
    currentQuestion() {
      return this.questions[this.currentIndex];
    },
    progressWidth() {
      if (this.questions.length === 0) {
        return "0%";
      }
      return ((this.currentIndex + 1) / this.questions.length) * 100 + "%";
    },
    parsedOptions() {
      // 动态解析选项
      if (this.currentQuestion.answer) {
        // 如果存在 answer 字段，解析为 JSON
        try {
          const parsed = JSON.parse(this.currentQuestion.answer);
          return parsed.value || [];
        } catch (e) {
          console.error("无法解析 answer 字段：", this.currentQuestion.answer);
          return [];
        }
      } else if (this.currentQuestion.item) {
        // 如果存在 item 字段，提取 item.name
        return this.currentQuestion.item.map((option) => option.name);
      }
      return [];
    },
  },
  methods: {
    //开始测评
    handleCheckboxChange(event) {
      // 获取当前问题的答案
      const currentAnswer = this.answers[this.currentQuestion.id] || [];

      // 当前点击的选项
      const clickedOption = event.target.value;

      if (this.currentIndex === 31 || this.currentIndex === 4) {
        // 如果选中了“无不适感觉”，清空其他选项，仅保留“无不适感觉”
        if (clickedOption === "无不适感觉") {
          this.$set(this.answers, this.currentQuestion.id, ["无不适感觉"]);
        } else if (clickedOption === "以上均无") {
          // 如果选中了“以上均无”，清空其他选项，仅保留“以上均无”
          this.$set(this.answers, this.currentQuestion.id, ["以上均无"]);
        } else {
          // 如果答案中包含“无不适感觉”或“以上均无”，移除它们
          const updatedAnswers = currentAnswer.filter(
            (option) => option !== "无不适感觉" && option !== "以上均无"
          );

          // 添加当前点击的选项
          updatedAnswers.push(clickedOption);

          // 更新答案
          this.$set(this.answers, this.currentQuestion.id, updatedAnswers);
        }
        this.saveAnswer(); // 保存进度
      }

      // 判断是否是需要限制的题目
      if (
        (this.currentIndex === 34 || this.currentIndex === 37) &&
        this.answers[this.currentQuestion.id].length > 3 // 修正为正确检查当前答案长度
      ) {
        Toast("超出选项不会记录");

        // 从当前答案中移除点击的选项
        const updatedAnswers = this.answers[this.currentQuestion.id].filter(
          (option) => option !== clickedOption
        );

        // 更新答案
        this.$set(this.answers, this.currentQuestion.id, updatedAnswers);
        this.saveAnswer(); // 保存进度
      }
    },
    // 加载问题
    async fetchQuestions() {
      const userId = this.$route.query.userId;
      console.log(userId);

      try {
        const response = await fetch(
          "https://assess.eapchina.net/getQuestions885"
          // "http://192.168.1.217:8081/assess/getQuestions885"
        );
        const data = await response.json();
        this.questions = Object.values(data.data).flat();
        this.questions.forEach((question) => {
          if (!question.type) {
            console.warn(`题目 ID ${question.id} 缺少 type 字段`);
          }
          // 初始化多选题为数组
          if (
            question.type === "checkbox" &&
            !Array.isArray(this.answers[question.id])
          ) {
            this.$set(this.answers, question.id, []);
          }
        });
        const part10Questions = this.questions.filter((q) => q.part === 11);
        const otherQuestions = this.questions.filter((q) => q.part !== 11);
        this.questions = [...otherQuestions, ...part10Questions];

        this.loadProgress(); // 加载保存的进度
      } catch (error) {
        console.error("加载题目失败：", error);
      }
    },

    // 保存答案
    saveAnswer() {
      localStorage.setItem("answers", JSON.stringify(this.answers));
    },

    // 加载进度
    loadProgress() {
      const savedAnswers = localStorage.getItem("answers");
      if (savedAnswers) {
        this.answers = JSON.parse(savedAnswers);
      }

      // 回显“其他”内容到输入框
      this.inputvalue = this.answers[`${this.currentQuestion.id}_other`] || "";

      // 查找第一个未回答的问题
      this.currentIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];
        if (question.type === "checkbox") {
          return !answer || answer.length === 0; // 多选题未选中
        }

        // 判断单选和下拉框是否为空（区分 0 和未选择）
        if (question.type === "select" || question.type === "radio") {
          return answer === null || answer === undefined || answer === "";
        }

        // 其他题型的默认检查
        return answer === null || answer === undefined || answer === "";
      });

      // 如果所有问题都已回答，则将索引设置为最后一个问题
      if (this.currentIndex === -1) {
        this.currentIndex = this.questions.length - 1;
      }
    },
    nextQuestion() {
      const currentAnswer = this.answers[this.currentQuestion.id];

      // 检查当前题目是否是多选题，并包含“其他”选项
      if (
        Array.isArray(currentAnswer) && // 确保是数组
        (currentAnswer.includes("其他") || currentAnswer.includes("其它"))
      ) {
        if (!this.inputvalue) {
          Toast.fail("其他内容未填写");
          return;
        }
        // 保存“其他”的输入值到对应的题目中
        this.$set(
          this.answers,
          `${this.currentQuestion.id}_other`,
          this.inputvalue
        );
      }

      // 校验当前题目的答案是否已选择
      if (
        (this.currentQuestion.type === "radio" && !currentAnswer) || // 单选未选择
        (this.currentQuestion.type === "checkbox" &&
          (!currentAnswer || currentAnswer.length === 0)) || // 多选未选择
        (this.currentQuestion.type === "select" &&
          (currentAnswer === null ||
            currentAnswer === undefined ||
            currentAnswer === "")) // 下拉框未选择
      ) {
        Toast.fail("请选择选项后再继续！");
        return; // 阻止下一步
      }

      // 如果答案已选择，进入下一题
      if (this.currentIndex < this.questions.length - 1) {
        this.currentIndex++;
        this.inputvalue =
          this.answers[`${this.currentQuestion.id}_other`] || ""; // 更新输入框内容
        this.saveAnswer(); // 保存进度
      }
    },

    // 上一题
    prevQuestion() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.inputvalue =
          this.answers[`${this.currentQuestion.id}_other`] || ""; // 更新输入框内容
        this.saveAnswer(); // 保存进度
      }
    },

    async submitAnswers() {
      console.log(this.answers["97"], "this.answers");
      this.answers["99"] = this.jianyi;

      // 校验手机号逻辑
      // const phoneRegex = /^1[3-9]\d{9}$/; // 简单的中国手机号正则
      // if (!this.phone || !phoneRegex.test(this.phone)) {
      //   Toast.fail("请输入正确的手机号码！");
      //   return; // 阻止提交
      // }
      // this.answers["131"] = this.phone;
      // 检查未完成题目
      const unansweredIndex = this.questions.findIndex((question) => {
        const answer = this.answers[question.id];

        if (question.type === "checkbox") {
          return !answer || answer.length === 0; // 多选题未选中
        }

        // 单选和下拉框检查是否为空（区分 0 和未选择）
        if (question.type === "select" || question.type === "radio") {
          return answer === null || answer === undefined || answer === "";
        }

        // 其他题型的默认检查
        return answer === null || answer === undefined || answer === "";
      });

      if (unansweredIndex !== -1) {
        // 有未完成的题目，跳转到第一道未完成的题目
        this.currentIndex = unansweredIndex;
        Toast.fail(`请完成第 ${unansweredIndex + 1} 题后再提交！`);
        return;
      }

      // 数据处理
      const daan = {};
      for (const [questionId, answer] of Object.entries(this.answers)) {
        const question = this.questions.find((q) => q.id == questionId);
        if (!question) continue;

        if (question.lbassessment_id) {
          // 处理 `lb3519-9839` 格式
          const selectedOption = question.item.find(
            (option) => option.name === answer || option.id == answer
          );
          if (selectedOption) {
            daan[`lb${question.lbassessment_id}-${question.id}`] =
              selectedOption.id.toString();
          }
        } else {
          // 处理 `vote-0` 格式
          const voteKey = `vote-${questionId}`;
          if (Array.isArray(answer)) {
            // 多选题的答案拼接成逗号分隔的字符串
            daan[voteKey] = answer.join(",");
          } else {
            // 单选题或输入框答案直接使用
            daan[voteKey] = answer;
          }
        }
      }

      daan["userId"] = localStorage.getItem("userId");
      console.log("提交的答案：", daan);

      Toast.loading({
        message: "正在计算结果",
        forbidClick: true,
        duration: 0,
      });

      try {
        const response = await fetch(
          "https://assess.eapchina.net/saveTheAnswer4HC",
          // "http://192.168.1.217:8081/assess/saveTheAnswer4HC",
          {
            method: "POST",
            body: JSON.stringify(daan),
          }
        );

        if (!response.ok) {
          throw new Error(`提交失败，状态码：${response.status}`);
        }

        const result = await response.json();
        Toast.success("提交成功");
        localStorage.setItem("voteLogId", result.voteLogId); // 清理缓存数据
        setTimeout(() => {
          this.$router.push("/CP/huachuang/answerOver");
        }, 1000);
        localStorage.removeItem("answers"); // 清理缓存数据
      } catch (error) {
        Toast.fail("提交失败");
      }
    },
  },

  created() {
    this.fetchQuestions();
  },
};
</script>

<style scoped>
.questionnaire {
  font-family: "Arial", sans-serif;
  max-width: 720px;
  margin: auto;
  padding: 20px;
  box-sizing: border-box;
  /* border: 1px solid #ccc; */
  border-radius: 10px;
  /* background: #fff; */
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); */
}

.title {
  font-size: 1.8em;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.progress-bar {
  position: relative;
  background: #e0e0e0;
  height: 8px;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 20px;
}

.progress {
  background: #76c7c0;
  height: 100%;
}

.progress-text {
  font-size: 0.9em;
  margin-top: 8px;
  display: block;
  text-align: center;
}

.question-title {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #444;
  line-height: 30px;
  margin-bottom: 30px;
}

.options {
  display: flex;
  flex-direction: column;
}

.option {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.radio-input,
.checkbox-input {
  margin-right: 10px;
}

.radio-label,
.checkbox-label {
  font-size: 1em;
  color: #555;
}

.select-container {
  margin-top: 10px;
}

.custom-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
  color: #555;
  background: #f9f9f9;
}

.navigation {
  margin-top: 20px;
  text-align: center;
}

.nav-button,
.submit-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  background-color: #76c7c0;
  color: white;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s;
}

.nav-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.nav-button:hover:not(:disabled),
.submit-button:hover {
  background-color: #5ba9a1;
}

@media (max-width: 768px) {
  .questionnaire {
    padding: 15px;
  }

  .title {
    font-size: 1.5em;
  }

  .nav-button,
  .submit-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
  .introduction {
    text-align: center;
    padding: 20px;
    max-width: 720px;
    margin: auto;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100%;
  }

  .introduction .content {
    font-size: 1em;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
    color: #444;
  }

  .start-button {
    padding: 12px 24px;
    font-size: 1.1em;
    border: none;
    border-radius: 6px;
    background-color: #76c7c0;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .start-button:hover {
    background-color: #5ba9a1;
  }
}
.qita {
  position: relative;
  left: 70px;
  top: -30px;
}
.qita2 {
  margin-top: 10px;
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  padding: 5px;
  border: #ccc 1px solid;
}
</style>
